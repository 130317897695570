import mapboxgl from "mapbox-gl/dist/mapbox-gl";

// console.log("Mapbox");
// console.log(mapboxgl);

function createMap() {
	mapboxgl.accessToken =
		"pk.eyJ1IjoiZTEwayIsImEiOiJjbGhrcDJkdncwdHcwM2t1cHV0dXRldXd5In0.0h6WaXlyNhE6g2o1M2ToKA";
	const mq = window.matchMedia("(min-width: 768px)");
	const mapContainer = document.querySelector("#mapbox-disaster-areas");
	const mapStyle = mapContainer.dataset.mapStyle;
	let mapAtmosEnabled = false;
	let mapSpinEnabled = false;
	let mapOptions = {};

	// Map options
	switch (mapStyle) {
		// Rotating Satellite Globe (rotating-satellite-globe)
		case "rotating-satellite-globe":
			mapOptions = {
				container: "mapbox-disaster-areas",
				style: "mapbox://styles/mapbox/satellite-v9?optimize=true",
				projection: "globe",
				zoom: mq.matches ? 3.5 : 2.5,
				center: mq.matches ? [-90, 40] : [-80, 30]
			};
			mapAtmosEnabled = true;
			mapSpinEnabled = true;
			break;
		// Satellite Streets (satellite-streets)
		case "satellite-streets":
			mapOptions = {
				container: "mapbox-disaster-areas",
				style: "mapbox://styles/mapbox/satellite-streets-v12?optimize=true",
				projection: "mercator",
				zoom: mq.matches ? 3 : 2,
				center: mq.matches ? [-100, 40] : [-80, 30]
			};
			break;
		// Streets (streets)
		case "streets":
		default:
			mapOptions = {
				container: "mapbox-disaster-areas",
				style: "mapbox://styles/mapbox/streets-v12?optimize=true",
				projection: "mercator",
				zoom: mq.matches ? 3 : 2,
				center: mq.matches ? [-100, 40] : [-80, 30]
			};
			break;
	}

	// Map
	const map = new mapboxgl.Map(mapOptions);

	// Markers
	const markerSize = 100;

	const pulsingMarker = {
		width: markerSize,
		height: markerSize,
		data: new Uint8Array(markerSize * markerSize * 4),
		onAdd: function () {
			const canvas = document.createElement("canvas");
			canvas.width = this.width;
			canvas.height = this.height;
			this.context = canvas.getContext("2d", {
				willReadFrequently: true
			});
		},
		render: function () {
			const duration = 1600;
			const t = (performance.now() % duration) / duration;
			const radius = (markerSize / 2) * 0.3;
			const outerRadius = (markerSize / 2) * 0.2 * t + radius;
			const context = this.context;

			// Draw the outer circle
			context.clearRect(0, 0, this.width, this.height);
			context.beginPath();
			context.arc(
				this.width / 2,
				this.height / 2,
				outerRadius,
				0,
				Math.PI * 2
			);
			context.fillStyle = `rgba(255, 255, 255, ${1 - t})`;
			context.fill();

			// Draw the inner circle
			context.beginPath();
			context.arc(
				this.width / 2,
				this.height / 2,
				radius,
				0,
				Math.PI * 2
			);
			context.fillStyle = "rgba(235, 43, 45, 1)";
			context.strokeStyle = "white";
			context.lineWidth = 2;
			context.fill();
			context.stroke();

			// Update this image's data with data from the canvas
			this.data = context.getImageData(
				0,
				0,
				this.width,
				this.height
			).data;

			// Continuously repaint the map, resulting
			// in the smooth animation of the dot
			map.triggerRepaint();

			// Return `true` to let the map know that the image was updated
			return true;
		}
	};

	const nonPulsingMarker = {
		width: markerSize,
		height: markerSize,
		data: new Uint8Array(markerSize * markerSize * 4),
		onAdd: function () {
			const canvas = document.createElement("canvas");
			canvas.width = this.width;
			canvas.height = this.height;
			this.context = canvas.getContext("2d", {
				willReadFrequently: true
			});
		},
		render: function () {
			const duration = 1600;
			const t = (performance.now() % duration) / duration;
			const radius = (markerSize / 2) * 0.3;
			const outerRadius = (markerSize / 2) * 0.2 * t + radius;
			const context = this.context;

			// Draw the outer circle
			// context.clearRect(0, 0, this.width, this.height);
			// context.beginPath();
			// context.arc(
			// 	this.width / 2,
			// 	this.height / 2,
			// 	outerRadius,
			// 	0,
			// 	Math.PI * 2
			// );
			// context.fillStyle = `rgba(255, 255, 255, ${1 - t})`;
			// context.fill();

			// Draw the inner circle
			context.beginPath();
			context.arc(
				this.width / 2,
				this.height / 2,
				radius,
				0,
				Math.PI * 2
			);
			context.fillStyle = "rgba(235, 43, 45, 1)";
			context.strokeStyle = "white";
			context.lineWidth = 2;
			context.fill();
			context.stroke();

			// Update this image's data with data from the canvas
			this.data = context.getImageData(
				0,
				0,
				this.width,
				this.height
			).data;

			// Continuously repaint the map, resulting
			// in the smooth animation of the dot
			// map.triggerRepaint();

			// Return `true` to let the map know that the image was updated
			return true;
		}
	};

	const selectedMarker = {
		width: markerSize,
		height: markerSize,
		data: new Uint8Array(markerSize * markerSize * 4),
		onAdd: function () {
			const canvas = document.createElement("canvas");
			canvas.width = this.width;
			canvas.height = this.height;
			this.context = canvas.getContext("2d");
		},
		render: function () {
			const radius = (markerSize / 2) * 0.3;
			const context = this.context;

			// Draw the inner circle
			context.beginPath();
			context.arc(
				this.width / 2,
				this.height / 2,
				radius,
				0,
				Math.PI * 2
			);
			context.fillStyle = "rgba(255, 255, 255, 1)";
			context.strokeStyle = "white";
			context.lineWidth = 2;
			context.fill();
			context.stroke();

			this.data = context.getImageData(
				0,
				0,
				this.width,
				this.height
			).data;

			return true;
		}
	};

	// Atmosphere
	function atmosphere(map) {
		// https://docs.mapbox.com/mapbox-gl-js/style-spec/fog/
		map.on("style.load", () => {
			map.setFog({
				color: "#8d98b1", // Lower atmosphere
				"high-color": "#1f3566", // Upper atmosphere
				"horizon-blend": 0.08, // Atmosphere thickness (default 0.2 at low zooms)
				"space-color": "#1f3566", // Background color
				"star-intensity": 0.2 // Background star brightness (default 0.35 at low zoooms )
			});
		});
	}
	if (mapAtmosEnabled) {
		const mapAtmosphere = atmosphere(map);
	}

	// Spin
	function spin(map) {
		// The following values can be changed to control rotation speed:
		// At low zooms, complete a revolution every two minutes.
		const secondsPerRevolution = 120;
		// Above zoom level 5, do not rotate
		const maxSpinZoom = 5;
		// Rotate at intermediate speeds between zoom levels 3 and 5
		const slowSpinZoom = 3;

		let userInteracting = false;
		let spinEnabled = true;

		function spinGlobe() {
			const zoom = map.getZoom();
			if (
				spinEnabled &&
				!userInteracting &&
				zoom < maxSpinZoom &&
				mq.matches
			) {
				let distancePerSecond = 360 / secondsPerRevolution;
				if (zoom > slowSpinZoom) {
					// Slow spinning at higher zooms
					const zoomDif =
						(maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
					distancePerSecond *= zoomDif;
				}
				const center = map.getCenter();
				center.lng -= distancePerSecond;
				// Smoothly animate the map over one second.
				// When this animation is complete, it calls a 'moveend' event.
				map.easeTo({ center, duration: 1000, easing: (n) => n });
			}
		}
		spinGlobe();

		// Pause spinning on interaction
		map.on("mousedown", () => {
			userInteracting = true;
		});

		// Restart spinning the globe when interaction is complete
		map.on("mouseup", () => {
			userInteracting = false;
			spinGlobe();
		});

		// These events account for cases where the mouse has moved
		// off the map, so 'mouseup' will not be fired.
		map.on("dragend", () => {
			userInteracting = false;
			spinGlobe();
		});

		map.on("pitchend", () => {
			userInteracting = false;
			spinGlobe();
		});

		map.on("rotateend", () => {
			userInteracting = false;
			spinGlobe();
		});

		// When animation is complete, start spinning if there is no ongoing interaction
		map.on("moveend", () => {
			spinGlobe();
		});
	}
	if (mapSpinEnabled) {
		const mapSpin = spin(map);
	}

	// Locations
	function locationSource(map) {
		let features = [];

		mapLocationSource.map((location, index) => {
			features.push({
				type: "Feature",
				properties: {
					id: index,
					title: `<h3>${location.title}</h3>`,
					description: location.description,
					link: location.link
				},
				geometry: {
					type: "Point",
					coordinates: [
						location.coordinates.lng,
						location.coordinates.lat
					]
				}
			});
		});

		map.addSource("locations", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features
			}
		});
	}

	function locationLayers(map) {
		// Pulsing
		map.addLayer({
			id: "locations",
			type: "symbol",
			source: "locations",
			layout: {
				"icon-image": "pulsing-marker",
				"icon-allow-overlap": true
			}
		});
	}

	function locationPopup(map) {
		// When a click event occurs on a feature in the places layer, open a popup at the location of the feature, with description HTML from its properties
		map.on("click", "locations", (e) => {
			// Copy coordinates array
			const coordinates = e.features[0].geometry.coordinates.slice();
			const title = e.features[0].properties.title;
			const description = e.features[0].properties.description;
			let currentPoint = e.features[0].properties.id;

			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}

			new mapboxgl.Popup({
				anchor: "left"
			})
				.setLngLat(coordinates)
				.setHTML(title + description)
				.addTo(map);

			// Set white maker on click
			map.setLayoutProperty("locations", "icon-image", [
				"match",
				["get", "id"],
				currentPoint,
				"selected-marker",
				"pulsing-marker"
			]);
		});

		// Change the cursor to a pointer when the mouse is over the markers layer
		map.on("mouseenter", "locations", () => {
			map.getCanvas().style.cursor = "pointer";
		});

		// Change it back to a pointer when it leaves
		map.on("mouseleave", "locations", () => {
			map.getCanvas().style.cursor = "";
		});
	}

	// On map load
	map.on("load", () => {
		map.addImage("pulsing-marker", pulsingMarker, { pixelRatio: 2 });
		map.addImage("selected-marker", selectedMarker, { pixelRatio: 2 });

		const mapLocationSource = locationSource(map);
		const mapLocationLayer = locationLayers(map);
		const mapLocationPopup = locationPopup(map);
	});
}

// Check if disaster areas map section is in viewport before loading
const el = document.querySelector("#mapbox-disaster-areas");
const observer = new window.IntersectionObserver(
	([entry]) => {
		if (entry.isIntersecting) {
			// console.log("Enter");
			createMap();
			observer.unobserve(entry.target);
		} else {
			// console.log("Exit");
			// Do nothing
		}
	},
	{
		root: null,
		rootMargin: "0px 0px 150px 0px",
		threshold: 0
	}
);
observer.observe(el);
